.footer-container {
    background-color: #000000;
    padding: -3% 0 0 -3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.social-media {
    max-width: 1000px;
    width: 100%;
}

.social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 10px auto 0 auto;
}

.website-center {
    color: #fff;
    margin-bottom: 10px;
    text-align: center;
    font-size: small;
}

@media screen and (max-width: 820px) {
    .social-media-wrap {
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {}

.fa {
    padding: 10px;
    font-size: 17px;
    width: 35px;
    height: 35px;
    text-align: center;
    text-decoration: none;
    margin: 2px 2px;
}

.fa-facebook {
    background: #3b5998;
    color: white;
}

.fa-twitter {
    background: #55acee;
    color: white;
}

.fa-linkedin {
    background: #007bb5;
    color: white;
}

.fa-youtube {
    background: #bb0000;
    color: white;
}

.fa-instagram {
    background: #125688;
    color: white;
}