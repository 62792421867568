   .navbar {
       background-color: rgb(0, 0, 0);
       height: 80px;
       display: flex;
       justify-content: center;
       align-items: center;
       font-size: 1.2rem;
       /* position: sticky; */
       position: fixed;
       width: 100%;
       top: 0;
       z-index: 20;
       text-decoration: none;
   }
   
   .nav-container {
       position: fixed;
       background-color: rgb(0, 0, 0);
       font-size: 1.2rem;
       top: 0;
       z-index: 20;
       text-decoration: none;
       display: flex;
       justify-content: center;
       align-items: center;
       width: 100%;
       height: 80px;
       /* max-width: 1500px; */
   }
   /*
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

#navbar {
    overflow: hidden;
    background-color: #333;
}

.sticky+.content {
    padding-top: 60px;
}

*/
   /* .main{
  background: url("./pages/image/cover.jpeg");
  background-position: center;
  background-size:cover;
  height: 100vh;
  width: 100wh;
 
} */
   
   .nav-logo {
       color: rgb(111, 215, 247);
       display: flex;
       align-items: center;
       margin-left: 5px;
       margin-bottom: 5px;
       cursor: pointer;
       text-decoration: none;
       flex-grow: 1;
   }
   
   .nav-menu {
       display: flex;
       list-style: none;
       text-align: center;
       align-items: left;
       font-weight: 100;
   }
   
   .nav-links {
       color: rgb(255, 255, 255);
       text-decoration: none;
       padding: 0.5rem 1rem;
       height: 100%;
       border-bottom: 3px solid transparent;
       font-weight: 100;
   }
   
   .fa-code {
       margin-left: 1rem;
   }
   
   .nav-item {
       line-height: 35px;
       margin-right: 1.5rem;
       margin-top: 20px;
   }
   
   .nav-item:after {
       content: "";
       display: block;
       height: 3px;
       width: 0;
       background: transparent;
       text-decoration: none;
       transition: width 0.7s ease, background-color 0.5s ease;
   }
   /* .nav-item:hover:after {
  width: 100%;
  background: rgb(0, 195, 255);
} */
   
   .nav-links:hover {
       background-color: rgb(59, 58, 58);
       color: white;
       transition: all 0.5s;
       border-radius: 1px;
       /* border-bottom: 3px solid rgb(0, 195, 255); */
   }
   
   .nav-item span {
       color: red;
       font-weight: 700;
   }
   
   .nav-item span:hover {
       color: white;
   }
   
   .nav-item .active {
       border-bottom: 3px solid rgb(0, 195, 255);
   }
   /* ............................ */
   /* .nav-item:hover:after {
  width: 100%;
  background: rgb(0, 195, 255);
} */
   
   .dropdown {
       line-height: 35px;
       margin-right: 1.5rem;
       margin-top: 20px;
   }
   
   .dropdown:after {
       content: "";
       display: block;
       height: 3px;
       width: 0;
       background: transparent;
       text-decoration: none;
       transition: width 0.7s ease, background-color 0.5s ease;
   }
   
   .dropdown .active {
       border-bottom: 3px solid rgb(255, 136, 0);
   }
   
   .dropdown span {
       color: red;
       font-weight: 700;
   }
   
   .dropdown span:hover {
       color: white;
   }
   
   .dropdown .dropbtn {
       font-size: 20px;
       border: none;
       outline: none;
       color: rgb(255, 255, 255);
       /* padding: 0px 0px; */
       background-color: inherit;
       font-family: inherit;
       margin: 0;
   }
   
   .dropdown-content {
       display: none;
       color: rgb(255, 255, 255);
       font-size: 15px;
       position: absolute;
       background-color: rgb(78, 81, 82);
       min-width: 123px;
       box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
       z-index: 1;
   }
   
   .dropdown-content a {
       float: none;
       /* color: rgb(68, 5, 5); */
       padding: 5px 10px;
       text-decoration: none;
       display: block;
       text-align: left;
   }
   
   .dropdown-content a:hover {
       background-color: #ff0000;
   }
   
   .dropdown:hover .dropdown-content {
       display: block;
   }
   /* ...................... */
   /* ....................... */
   
   .icon-barsd {
       position: fixed;
       top: 80%;
       -webkit-transform: translateY(-50%);
       -ms-transform: translateY(-50%);
       transform: translateY(-50%);
       /* opacity: 0.8; */
       z-index: 10;
       /* border: 4px solid rgba(255, 153, 0, 0.836); */
       /* box-shadow: 3px 3px; */
   }
   
   .icon-barsd a {
       margin-left: 10px;
       display: block;
       text-align: center;
       padding: 10px;
       transition: all 0.9s ease;
       color: white;
       font-size: 20px;
       /* box-shadow: 3px 3px; */
       /* text-shadow: 2px 2px; */
       /* box-shadow: 10px 10px 5px rgb(224, 224, 224); */
       border-radius: 5px;
   }
   
   .icon-barsd a:hover {
       background-color: rgb(255, 255, 255);
   }
   
   .WhatsApp {
       background: #2e8b36;
       color: white;
   }
   
   .phone {
       background: #ff6600;
       color: white;
   }
   
   .mail {
       background: #28afd1;
       color: white;
   }
   /* ....................... */
   
   .nav-icon {
       display: none;
   }
   
   @media screen and (max-width: 960px) {
       .nav-menu {
           display: flex;
           flex-direction: column;
           width: 100%;
           border-top: 1pxsolid #fff;
           position: absolute;
           top: 80px;
           left: -110%;
           opacity: 1;
           transition: all 0.5s ease;
       }
       .nav-menu.active {
           background: rgb(13, 14, 14);
           left: 0px;
           opacity: 1;
           transition: all 0.5s ease;
           z-index: 1;
       }
       .nav-item .active {
           color: rgb(0, 195, 255);
           border: none;
       }
       .nav-links {
           padding: 0.8rem;
           width: 100%;
           display: table;
       }
       .nav-icon {
           display: block;
           position: absolute;
           top: 0;
           right: 0;
           transform: translate(-100%, 60%);
           font-size: 1.8rem;
           cursor: pointer;
           color: rgb(0, 195, 255);
           /* background-color: rgb(0, 0, 0); */
       }
   }
   
   @media screen and (max-width: 960px) {
       .nav-itemb .active {
           color: rgb(0, 195, 255);
           border: none;
       }
   }
   
   @media (min-width: 768px) {
       .navbar-nav.navbar-right {
           position: absolute;
           right: 15%;
           grid-gap: 10px;
           transform: translatex(50%);
       }
   }
   
   .mobile-image {
       height: 100vh;
       width: 100%;
       background-position: center;
       background-size: cover;
       position: relative;
       background-image: url("../Images/cover.jpeg");
   }
   
   @media screen and (max-width: 400px) {
       .mobile-image {
           background-image: url("../Images/cover.jpeg");
           background-size: cover;
           background-position: center center;
           position: relative;
           object-fit: cover;
       }
   }
   /* @media screen and (min-width:769px) {
  .mobile-image {
   background-image: url('./pages/image/cover.jpeg');
   }
}  */