h1 {
    text-align: center;
    font-size: 3rem;
    color: rgb(70, 145, 202);
}

.icon-bar {
    position: fixed;
    top: 54.1%;
    transform: translateY(-50%);
    z-index: 10;
}

.icon-bar a {
    width: 17rem;
    height: 45px;
    background: gray;
    color: black;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.5rem;
    padding: 0.5rem;
    transition: all 0.4s linear;
    transform: translateX(-14.6rem);
}


/* .icon-bar a:hover{
  background-color: white;
} */


/* .icon-bar a:hover {
  transform: translateX(0);
} */

.icon-bar a .fa-instagram {
    padding-left: 0.5rem;
    font-size: 3rem;
    animation: letszoom 1s linear alternate-reverse infinite;
}

@keyframes letszoom {
    from {
        transform: scale(0.5);
    }
    to {
        transform: scale(0.6);
    }
}

.icon-bar .fa-facebook {
    background: #3b5998;
    color: white;
}

.icon-bar .fa-twitter {
    background: #55acee;
    color: white;
}

.icon-bar .fa-linkedin {
    background: #007bb5;
    color: white;
}

.icon-bar .fa-youtube {
    background: #bb0000;
    color: white;
}

.icon-bar .fa-instagram {
    background: rgb(235, 56, 100);
    color: white;
}

.icon-bar .fa-envelope {
    background: #ea4335;
    color: white;
}

.animated {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 17vh;
    background-color: transparent;
    font-family: "Mulish", sans-serif;
}

.animated h2 {
    position: relative;
    margin: 0px 0 0;
    font-size: 47px;
    font-weight: 800;
    color: black;
    z-index: 1;
    overflow: hidden;
}

.animated h2::before {
    content: "";
    position: absolute;
    left: 120%;
    width: 120%;
    height: 100%;
    background: linear-gradient(90deg, transparent 0%, #000 5%, #000 100%);
    animation: animate 5.5s linear forwards;
    animation-delay: 1s;
}

.animated h2 span {
    color: #55acee;
}

@media only screen and (max-width: 768px) {
    h1 {
        font-size: 25px;
        text-align: center;
    }
    .animated h2 {
        font-size: 150%;
    }
    .animated {
        min-height: 10vh;
    }
}

h2 {
    text-align: center;
    font-size: 1.5rem;
    color: rgb(7, 7, 7);
}

@media only screen and (max-width: 600px) {
    h2 {
        font-size: 15px;
        text-align: center;
    }
    .animated h2 {
        font-size: 155%;
    }
    .animated {
        min-height: 10vh;
    }
}

h4 {
    text-align: center;
    font-size: 1.2rem;
    color: rgb(0, 0, 0);
}

@media only screen and (max-width: 600px) {
    h4 {
        font-size: 12px;
        text-align: center;
    }
}