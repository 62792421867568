/* .hero-container {
  background: url("../components/pages/image/SROVC.jpeg") center center/cover
    no-repeat;
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
} */

.hero-container1 {
    background-image: url("../../Components/Images/cover.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    /* width: 100%; */
}

.hero-containert {
    background: url("../../Components/Images/blue.png") center center/cover no-repeat;
    height: 15vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-containerp {
    background: url("../../Components/Images/blue.png") center center/cover no-repeat;
    height: 15vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/*  

.hero-containerp1 {
    background: url("../../Components/Images/orange.jpg") center center/cover no-repeat;
    height: 20vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

*/

.hero-containers {
    background: url("../../Components/Images/blue.png") center center/cover no-repeat;
    height: 15vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-containerpp {
    background: url("../../Components/Images/partnerwithus.jpg") center center/cover no-repeat;
    height: 15vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.hero-container>h1 {
    color: #0d6efd;
    font-size: 50px;
    margin-top: -40px;
}

.hero-container>p {
    margin-top: 15px;
    color: #fff;
    font-size: 32px;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle {
    margin-left: 4px;
}


/* home page cover */

@media only screen and (max-width: 600px) {
    .hero-container1 {
        background-image: url("../../Components/Images/radar.jpg") center center cover no-repeat;
    }
}

@media screen and (max-width: 768px) {
    .hero-containerh {
        height: 30vh;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .hero-container>h1 {
        font-size: 30px;
        margin-top: 10px;
        width: 200px;
        height: 300px;
        object-fit: cover;
    }
    .hero-container>p {
        font-size: 30px;
    }
    .btn-mobile {
        display: block;
        text-decoration: none;
    }
    .btn {
        width: 100%;
    }
}

.hero-containert>h1 {
    color: #fff;
    font-size: 50px;
    margin-left: -80%;
}

@media screen and (max-width: 768px) {
    .hero-containert {
        height: 8vh;
        width: 100%;
    }
    .hero-containert>h1 {
        font-size: 30px;
        margin-left: -70%;
    }
}

.hero-containerp>h1 {
    color: #fff;
    font-size: 50px;
    margin-left: -80%;
}

@media screen and (max-width: 768px) {
    .hero-containerp {
        height: 8vh;
        width: 100%;
    }
    .hero-containerp>h1 {
        font-size: 30px;
        margin-left: -70%;
    }
}

.hero-containers>h1 {
    color: #fff;
    font-size: 50px;
    margin-left: -80%;
}

@media screen and (max-width: 768px) {
    .hero-containers {
        height: 8vh;
        width: 100%;
    }
    .hero-containers>h1 {
        font-size: 30px;
        margin-left: -60%;
    }
}

.hero-containerpp>h1 {
    color: #fff;
    font-size: 50px;
    margin-left: -70%;
}

@media screen and (max-width: 768px) {
    .hero-containerpp {
        height: 8vh;
        width: 100%;
    }
    .hero-containerpp>h1 {
        font-size: 30px;
        margin-left: -40%;
    }
}