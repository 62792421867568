.cards {
    padding: 4rem;
    background: #fff;
}

.colororg {
    background: rgb(240, 240, 240);
}

h11 {
    text-align: center;
    font-size: 70px;
    color: rgb(70, 145, 202);
}

strong1 {
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    font-size: 35px;
    color: rgb(255, 136, 0);
}

strong2 {
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    font-size: 35px;
    color: rgb(255, 0, 0);
}

hh1 {
    text-align: center;
    font-size: 70px;
    color: rgb(70, 145, 202);
}

h111 {
    text-align: center;
    font-size: 50px;
    color: rgb(255, 255, 255);
}

hb1 {
    text-align: center;
    font-size: 40px;
    color: rgb(255, 255, 255);
}

hb2 {
    text-align: center;
    font-size: 120px;
    color: rgb(10, 10, 10);
}

hc1 {
    text-align: center;
    font-size: 40px;
    color: rgb(255, 255, 255);
}

hc2 {
    text-align: center;
    font-size: 20px;
    color: rgb(10, 10, 10);
}

hpt {
    font-size: 1.3rem;
    color: rgb(0, 0, 0);
}

.cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
}

.cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
}

.cards__items {
    margin-bottom: 24px;
}

.cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
}

.cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
}

.cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
}

.cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
}

.cards__item__img:hover {
    transform: scale(1.1);
}

.cards__item__info {
    padding: 20px 30px 30px;
}

.cards__item__text {
    color: rgb(25, 95, 128);
    font-size: 32px;
    line-height: 38px;
}

@media only screen and (min-width: 1200px) {
    .content__blog__container {
        width: 84%;
    }
}

@media only screen and (min-width: 1024px) {
    .cards__items {
        display: flex;
    }
}

@media only screen and (max-width: 1024px) {
    .cards__item {
        margin-bottom: 2rem;
    }
}


/* .h111{
    background-image: url("./img/1.jpg");
    background-color: #cccccc;
  }   */

ha {
    text-align: center;
    font-size: 25px;
    color: rgb(70, 145, 202);
}

hb {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
}

@media only screen and (max-width: 600px) {
    hh1 {
        font-size: 15px;
        text-align: center;
    }
}

@media only screen and (max-width: 600px) {
    hb {
        font-size: 15px;
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    ha {
        font-size: 25px;
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    hc1 {
        font-size: 25px;
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    hc2 {
        font-size: 20px;
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    hpt {
        font-size: 15px;
        text-align: justify;
    }
}

@media only screen and (max-width: 768px) {
    hb1 {
        font-size: 25px;
        text-align: justify;
    }
}

@media only screen and (max-width: 768px) {
    hb2 {
        font-size: 20px;
        text-align: justify;
    }
}